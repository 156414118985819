/**
 * Created by Mariam.Melkonyan on 6/27/2016.
 */
import {ChangeDetectionStrategy, Component, forwardRef, ViewChild, ViewEncapsulation} from "@angular/core";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {MultilingualComponent} from "./sis-multilingual.component";
import {ControlMetadata} from "@synisys/idm-dynamic-controls-metadata";
import {MultilingualTextareaSettings} from "../control-settings/sis-multilingual-textarea.settings";

const MULTILINGUAL_TEXTAREA_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultilingualTextarea),
    multi: true
};

import "./sis-multilingual-textarea.component.css";

@Component({
    moduleId: module.id,
    selector: "sis-multilingual-textarea",
    templateUrl: "sis-multilingual-textarea.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MULTILINGUAL_TEXTAREA_CONTROL_VALUE_ACCESSOR]
})
@ControlMetadata({
    groups: [
        {
            identity: "Controls",
            name: "Controls",
        },
    ],
    template: `%{#isreadonly}
                   <ng-container *ngIf="!%{isreadonly}">
                        <sis-multilingual-textarea [maxLength]="%{maxLength}" [placeholder]="%{placeholder}" [id]="'%{id}'" [title]="title"
                                            [languages]="languages" [currentLanguageId]="currentLanguageId" [(ngModel)]="%{field}" [hint]="%{hint}" 
                                            [characterCountSettings]="%{characterCountSettings}" [isRequired]="isRequired" [validations]="validations"></sis-multilingual-textarea>
                   </ng-container>
                   <ng-container *ngIf="%{isreadonly}">
                        <label *ngIf="%{field}?.getValue(currentLanguageId)" [id]="'%{id}'">{{%{field}?.getValue(currentLanguageId)}}</label>
                        <sis-custom-label *ngIf="!%{field}?.getValue(currentLanguageId)" [key]="'reporting.controls.chart-designer.placeholder.noData'"></sis-custom-label>
                   </ng-container>
               %{/isreadonly}
               %{^isreadonly}
                   <sis-multilingual-textarea [maxLength]="%{maxLength}" [placeholder]="%{placeholder}" [id]="'%{id}'" [title]="title"
                                                [languages]="languages" [currentLanguageId]="currentLanguageId" [(ngModel)]="%{field}" [hint]="%{hint}" 
                                                [characterCountSettings]="%{characterCountSettings}" [isRequired]="isRequired" [validations]="validations"></sis-multilingual-textarea>
               %{/isreadonly}`,
    iconInfo: "fa-text-height",
    name: "Multilingual textarea",
    settings: {
        main: MultilingualTextareaSettings
    },
    isFieldBound: true
})
export class MultilingualTextarea extends MultilingualComponent {

    closeMenu() {
        this.menuTrigger.closeMenu();
    }
}

