import {Component, Input, OnInit} from '@angular/core';
import {PanelPlacement} from './panel-placement';
import './sis-panel.component.css';

@Component({
               moduleId   : module.id,
               selector   : 'sis-panel',
               templateUrl: 'sis-panel.component.html',
           })

/**
 * Panel component is dynamic panel with dynamic content. *
 */
export class PanelComponent implements OnInit {
    /**
     * Size(width or height) of panel.
     * @type {number}
     */
    @Input()
    public size: number;
    /**
     * PanelPlacement or location of panel.
     * @type {PanelPlacement}
     */
    @Input()
    public panelPlacement: PanelPlacement;
    /**
     * Shows, panel overlays or not.
     * @type {boolean}
     */
    @Input()
    public isFloating: boolean;
    @Input()
    public id: string;
    private _styles: any;

    private isVertical: boolean;

    private _isOpened: boolean;

    private panelStyle: string;

    private _cssClasses: string;

    constructor() {
        this._isOpened = false;
        this.isFloating = false;
        this.panelStyle = '';
        this.size = 0;
        this.id = '';
    }

    get styles(): any {
        return this._styles;
    }

    set styles(value: any) {
        this._styles = value;
    }

    get cssClasses(): string {
        return this._cssClasses;
    }

    set cssClasses(value: string) {
        this._cssClasses = value;
    }

    public get isOpened(): boolean {
        return this._isOpened;
    }

    /**
     * State(opened or closed) of panel for toggling it.
     * @type {boolean}
     */
    @Input()
    public set isOpened(isOpened: boolean) {
        this._isOpened = isOpened;

        this.setCssClasses();
    }

    public ngOnInit(): void {

        if (this.isFloating) {
            this.panelStyle = 'sis-panel--floating ';
        }

        switch (this.panelPlacement) {
            case PanelPlacement.TOP:
                this.panelStyle += 'sis-panel-position--top';
                this.isVertical = true;
                break;
            case PanelPlacement.LEFT:
                this.panelStyle += 'sis-panel-position--left';
                this.isVertical = false;
                break;
            case PanelPlacement.RIGHT:
                this.panelStyle += 'sis-panel-position--right';
                this.isVertical = false;
                break;
            case PanelPlacement.BOTTOM:
                this.panelStyle += 'sis-panel-position--bottom';
                this.isVertical = true;
                break;
            default:
                throw new TypeError('Invalid panel placement was provided.');
        }

        this._styles = {
            height: this.isVertical ? `${this.size}px` : '',
            width : this.isVertical ? '' : `${this.size}px`,
        };

        this.setCssClasses();
    }

    private setCssClasses(): void {
        if (this._isOpened) {
            this._cssClasses = this.panelStyle + ' sis-panel-open';
        } else {
            this._cssClasses = this.panelStyle + ' sis-panel-closed';
        }
    }
}
