/**
 * Created by tamara.aprikyan on 7/12/2017.
 */
import {AfterViewInit, ContentChildren, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges} from "@angular/core";
import {SelectItemDirective} from "../shared/sis-select-item.directive";
import {SubscriptionManager} from "../shared/subscription-manager.decorator";

/**
 * BaseMenuComponent is base component for creating kendo menu.
 * TODO: Move this code/its tests to Menu and remove this.
 */
@SubscriptionManager()
export class BaseMenuComponent implements AfterViewInit {

    public $sm: SubscriptionManager;

    @Input()
    public title: string;

    @Input()
    public icon: string;

    @Input()
    public id: string;

    @Input()
    public overlapTrigger: boolean = false;

    @Output()
    public onSelect: EventEmitter<any> = new EventEmitter();

    /**
     * Menu items retrieved from the content enclosed with menu tags.
     * @type {QueryList<SelectItemDirective>}
     */
    @ContentChildren(SelectItemDirective)
    public menuItems: QueryList<SelectItemDirective>;

    /**
     * The array of processed menu items convenient for rendering.
     * @type {Array<any>}
     */
    public processedMenuItems: Array<any> = [];

    constructor() {}



    /**
     * Sets content children parents and creates menu with this items.
     */
    public ngAfterViewInit() {
        this.menuItems.forEach((menuItem: SelectItemDirective) => {
            menuItem.parent = this;
            this.processMenuItems(menuItem);
        });
        this.$sm = this.menuItems.changes.subscribe((changes: QueryList<SelectItemDirective>): void => {
            this.menuItems = changes;
            this.onItemsChanged();
        });
    }

    /**
     * Processes the menu data to more convenient format for rendering.
     * @param {SelectItemDirective} menuItem - The child directive.
     * @protected
     */
    protected processMenuItems(menuItem: SelectItemDirective) {
        // The case that menu item directive contains data not for single menu item, it contains data for multiple items.
        if (menuItem.data) {
            menuItem.data.forEach((dataItem: any) => {
                this.processedMenuItems.push({
                        text: dataItem[menuItem.nameField],
                        isDisabled: dataItem[menuItem.isEnabledField] && (!menuItem.isEnabled),
                        value: menuItem.valueField ? dataItem[menuItem.valueField] : dataItem,
                    }
                );
            });
        } else {
            this.processedMenuItems.push(
                {
                    text: menuItem.name,
                    isDisabled: !menuItem.isEnabled,
                    value: menuItem.value,
                },
            );
        }
    }

    /**
     * Handler for menu item changes.
     */
    public onItemsChanged() {
        this.processedMenuItems = [];
        this.menuItems.forEach((menuItemDirective: SelectItemDirective) => {
            menuItemDirective.parent = this;
            this.processMenuItems(menuItemDirective);
        });
    }

    /**
     * Event handler for menu item selection.
     * @param itemId
     */
    public onItemClicked(itemId: any) {
        this.onSelect.emit(itemId);
    }

    public trackByValue (index: number, item: any): any {
        return item ? item.value : null;
    }
}
