import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {RadioButtonItem} from "../shared/radio-button-item";

import "./sis-radio-buttons.component.css";  @Component({
    moduleId: module.id,
    selector: "sis-radio-buttons",
    templateUrl: "sis-radio-buttons.component.html"
})

export class RadioButtonsComponent implements OnInit {

    @Input()
    public deletedItemPlaceholder:string = "-- Deleted Record --";

    @Input()
    public items: RadioButtonItem[];

    @Input()
    public value: number|string;

    @Output()
    public valueChange: EventEmitter<number|string> = new EventEmitter();

    public deletedValue: number|string;

    constructor() {
    }

    public ngOnInit(): void {
        this.deletedValue = this.isDeletedValue();
    }

    public updateChangedValue(): void {
        if (this.deletedValue) {
            setTimeout(() => {
                this.deletedValue = null;
            }, 1000);
        }
        this.valueChange.emit(this.value);
    }

    public isDeletedValue (): number|string {
        this.items.forEach(item => {
            if (item.getId() === this.value) {
                return null;
            }
        });
        return this.value;
    }

    public trackByItemId (index: number, item: RadioButtonItem): number|string {
        return item.getId();
    }

}
