/**
 * Created by Anushavan.Hovsepyan on 11/23/2016.
 */
import {Directive, forwardRef} from "@angular/core";
import {SelectItemDirective} from "../shared/sis-select-item.directive";

/**
 * @deprecated use {@link SelectItemDirective} instead.
 * MenuItemDirective for initializing menu item.
 * Gets as input:
 *      data - The array of objects, which contains options data.
 *      value - The option value.
 *      name - The option name.
 *      group - The group of item.
 *      isEnabled - If item is enabled.
 *      valueField - The value field name.
 *      nameField - The name field name.
 *      groupField - The criteria name of grouping.
 *      isEnabledField - The enabled field name.
 * The valueField, the nameField, the groupField and the isEnabledField can be used only with data.
 */
@Directive({
    selector: "sis-menu-item, sis-menu-items",
    providers: [{provide: SelectItemDirective, useExisting: forwardRef(() => MenuItemDirective)}],
})
export class MenuItemDirective extends SelectItemDirective {
}
