import {
    Language, MultilingualString, MultilingualStringBuilder,
    LanguageDirection
} from "@synisys/idm-crosscutting-concepts-frontend";
import {
    ChangeDetectorRef,
    ElementRef,
    Input,
    OnChanges,
    OnInit, Optional, QueryList,
    SimpleChange,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from "@angular/core";
import {ControlValueAccessor} from "@angular/forms";
import {SisControlWithSettingsBase} from "../control-settings/sis-control-with-settings-base";
import {Direction} from "@angular/cdk/bidi";
import {Observable} from "rxjs/Observable";
import {ControlsTranslationService} from "../shared/controls-translation.service";
import {MatMenuTrigger} from "@angular/material";
import {CopyLanguage, FieldTitle, LanguageOption} from "./model/multilingual-interfaces";

export class MultilingualComponent extends SisControlWithSettingsBase implements ControlValueAccessor, OnInit, OnChanges {

    /**
     * Unique id for control. Mainly is used for automated testing.
     * @type {string}
     */
    @Input()
    id: string;

    /**
     * The languages of application. The languages are used to render the tabs for languages.
     * @type {Array<Language>}
     */
    @Input()
    languages: Array<Language>;

    /**
     * The current language. This value is provided when the control is created.
     * @type {number}
     */
    @Input()
    currentLanguageId: number;

    /**
     * The default language. This value is provided when the control is created.
     * @type {number}
     */
    @Input()
    defaultLanguageId: number;

    /**
     * The label for the input. Shown in the mat menu when in edit mode. Mainly for dynamic forms.
     * @type {any}
     */
    @Input()
    title: FieldTitle;

    /**
     * The text title label for the input. Shown in the mat menu when in edit mode.
     * @type {string}
     */
    @Input()
    titleText: string;

    /**
     * The placeholder of text area if the multilingual string is not filled yet.
     * @type {string}
     */
    @Input()
    placeholder: string;

    /**
     * Maximum length of the input field.
     * @type {number}
     */
    @Input()
    maxLength: number;

    /**
     * The state of multilingual component (enabled or disabled).
     * @type {boolean}
     */
    @Input()
    isDisabled: boolean = false;

    /**
     * Hint text to show under the input field. (empty or null to hide)
     * @type {string}
     */
    @Input()
    hintText: string;

    /**
     * Indicates whether the field is required or not. Used for required asterisk displaying in mat-menu.
     * @type {boolean}
     */
    @Input()
    isRequired: boolean = false;

    /**
     * Show/hide characters count.
     * @type {boolean}
     */
    @Input()
    showCharactersCount: boolean = false;

    /**
     * Show/hide validations. Will indicate that fields are required for empty language inputs.
     * @type {boolean}
     */
    @Input()
    showValidations: boolean = false;

    /**
     * Validations from the form. Used to determine when to show language input validation messages (mainly for dynamic forms).
     * @type {any}
     */
    @Input()
    set validations(value: any[]) {
        this.showValidations = value && value.length > 0;
    }

    /**
     * Array of LanguageOptions, holding corresponding properties for the opened panel items, used for input manipulation during editing.
     */
    public languageOptions: LanguageOption[];

    /**
     * LanguageOption holding corresponding language from  languageOptions which is currently in focus.
     */
    public selectedLanguage: LanguageOption;

    /**
     * Indicates whether the copy panel is open for any language in the menu.
     * @type {boolean}
     */
    public isCopyPanelOpen = false;

    /**
     * Array of languages kept internally to manipulate the language ordering based on possible currentLanguageId changes, while having the system order.
     */
    private _languagesArray: Language[] = [];

    /**
     * The multi-lingual text that is rendered in control. This value is passed as ngModel.
     * @type {MultilingualString}
     * @private
     */
    private _multilingualString: MultilingualString = null;

    /**
     * The primary language id, which is the currentLanguageId, if present, otherwise the defaultLanguageId.
     */
    public _primaryLanguageId: number;

    /**
     * Placeholders for callbacks. Used for implementing ControlValueAccessor interface
     */
    private _onTouchedCallback: () => {};

    private _onChangeCallback: (_: any) => {};


    @Input()
    public width: number = 340;

    @ViewChild('form')
    protected form: ElementRef;

    @ViewChild(MatMenuTrigger)
    protected menuTrigger: MatMenuTrigger;

    @ViewChildren('copyIcon', {read: MatMenuTrigger})
    protected copyPanelTriggers : QueryList<MatMenuTrigger>;

    @ViewChildren('input')
    protected inputs: QueryList<ElementRef>;

    @ViewChild('ngTextarea')
    protected ngTextarea: ElementRef;

    constructor(@Optional() private translationService: ControlsTranslationService,
                            private changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        this._primaryLanguageId = this.currentLanguageId || this.defaultLanguageId;
        !this.languages && (this.languages = []);

        if (!this._multilingualString) {
            let multilingualStringBuilder: MultilingualStringBuilder = MultilingualString.newBuilder();
            for (let language of this.languages) {
                multilingualStringBuilder.withValueForLanguage(language.getId(), null);
            }
            this._multilingualString = multilingualStringBuilder.build();
        }

        this.initLanguages();
    }

    /**
     * @inheritDoc
     */
    public ngOnChanges(changes: SimpleChanges): void {
        const languagesChanged: SimpleChange = changes["languages"];
        const currentLanguageIdChanged: SimpleChange = changes["currentLanguageId"];
        const hintChanged: SimpleChange = changes["hint"];
        const characterCountSettingsChanged: SimpleChange = changes["characterCountSettings"];

        if (languagesChanged) {
            this.initLanguages();
        }

        if (currentLanguageIdChanged) {
            this._primaryLanguageId = this.currentLanguageId;

            this.initLanguages();
            this.hintText = this.hint && this.currentLanguageId && this.hint.getValue(this.currentLanguageId);
        }

        if (hintChanged) {
            this.hintText = this.hint && this._primaryLanguageId && this.hint.getValue(this._primaryLanguageId);
        }

        if(characterCountSettingsChanged) {
           this.showCharactersCount = this.characterCountSettings && this.characterCountSettings.showCharacterCount;
        }
    }

    /**
     * Rearranges the languages to have the primary language be the fist element for the view and logical implementation.
     */
    private initLanguages() {
        this._languagesArray = this.languages ? [...this.languages]: [];

        const currentLanguage = this.currentLanguageId ? this._languagesArray.find(lang => lang.getId() === this.currentLanguageId) : null;
        const defaultLanguage = this.defaultLanguageId ? this._languagesArray.find((lang => lang.getId() === this.defaultLanguageId)) : null;

        defaultLanguage && this.moveLanguageToFront(defaultLanguage);
        currentLanguage && this.moveLanguageToFront(currentLanguage);

        this.initLanguageOptions();
    }

    private moveLanguageToFront(lang: Language): void {
        this._languagesArray.splice(this._languagesArray.indexOf(lang), 1);
        this._languagesArray.unshift(lang);
    }

    /**
     * ControlValueAccessor interface function. This function is used to catch the changes that happen in ngModel field.
     * @param multilingualString
     */
    writeValue(multilingualString: any) {
        if (multilingualString !== null && multilingualString !== undefined) {
            this._multilingualString = multilingualString;
            this.changeDetectorRef.detectChanges();
        }
    }

    /**
     * ControlValueAccessor interface function.
     */
    registerOnChange(fn: any) {
        this._onChangeCallback = fn;
    }

    /**
     * ControlValueAccessor interface function.
     */
    registerOnTouched(fn: any) {
        this._onTouchedCallback = fn;
    }

    /**
     * Returns direction of the language: LTR or RTL
     */
    public getLanguageDirection(languageId: number): Direction {
        if(this.languages !== undefined) {
            for (let language of this.languages) {
                if(language.getId() === languageId) {
                    return (language.getDirection() === LanguageDirection.RTL ? "rtl" : "ltr") as Direction;
                }
            }
        }
    }

    /**
     * Initiates the start of editing mode of multilingual component. This function is called when the main input area is clicked.
     */
    public onFormClicked() {
        if (this.languages && this.languages.length > 1) {
            this.menuTrigger.openMenu();
            this.initLanguageOptions();

            this.width = this.form.nativeElement.offsetWidth;
            this.inputs.first && this.inputs.first.nativeElement.focus();

            if (this.ngTextarea && this.inputs.first) {
                let areaHeight = this.ngTextarea.nativeElement.offsetHeight;
                this.inputs.first.nativeElement.style.height = areaHeight + 'px';
            }
        }
    }

    /**
     * Sets/resets the languageOptions with component's languages' necessary properties and their corresponding text values from the multilingual string.
     */
    private initLanguageOptions(): void {
        this.languageOptions = this._languagesArray.map(language => {
            const direction = (language.getDirection() === LanguageDirection.RTL ? "rtl" : "ltr") as Direction;
            const languageValue = this._multilingualString ? this._multilingualString.getValue(language.getId()) : null;

            return new LanguageOption(
                language.getId(),
                language.getAcronym(),
                direction,
                languageValue);
        });
    }

    /**
     * Changes the selected language. This function is called when one of the language text areas is clicked.
     * @param language
     */
    public onSelectedLangChange(language: LanguageOption): void {
        this.selectedLanguage = language;
    }

    public onDone(): void {
        this.menuTrigger.closeMenu();
        this.onApplyChanges();
    }

    public onApplyChanges(): void {
        if (!this._multilingualString || this.haveValuesChanged()) {
            let multilingualStringBuilder: MultilingualStringBuilder = MultilingualString.newBuilder();

            for (let language of this.languageOptions) {
                multilingualStringBuilder.withValueForLanguage(language.id, (!!language.value ? language.value.trim() : null));
            }
            this._multilingualString = multilingualStringBuilder.build();

            this._onChangeCallback && this._onChangeCallback(this._multilingualString);
        }
    }

    private haveValuesChanged(): boolean {
        let isChanged = false;
        this.languageOptions.forEach(value => {
            isChanged = isChanged || value.value !== this._multilingualString.getValue(value.id);
        });
        return isChanged;
    }

    public isFieldInvalid(): boolean {
        return this.isRequired && this.showValidations && this.languageOptions.some(this.isInputInvalid.bind(this));
    }

    public isInputInvalid(language: LanguageOption): boolean {
        return this.isRequired && this.showValidations && (language.value || '').trim().length === 0;
    }

    get primaryLanguageId(): number {
        return this._primaryLanguageId;
    }

    get multilingualString(): MultilingualString {
        return this._multilingualString;
    }

    public setMultilingualString(value: string) {
        let multilingualStringBuilder: MultilingualStringBuilder = MultilingualString.newBuilder();
        multilingualStringBuilder.withValueForLanguage(this._primaryLanguageId, value);

        this._multilingualString = multilingualStringBuilder.build();

        this._onChangeCallback && this._onChangeCallback(this._multilingualString);
    }

    public getMessage (key: string): Observable<string> {
        return this.translationService && this.translationService.getMessage(key);
    }

    public trackByLanguage(index: number, language: any): number {
        return language.id;
    }

    public onCopyIconClicked(targetLanguage: LanguageOption, index: number): void {
        this.selectedLanguage = targetLanguage;

        if (this.languageOptions.length > 2) {
            this.copyPanelTriggers.toArray()[index].openMenu();
            this.isCopyPanelOpen = true;
        } else {
            this.languageOptions.forEach(lang => {
                if (lang != targetLanguage) {
                    lang.value = targetLanguage.value;
                }
            })
        }

    }

    public onApplyCopy(copyLanguageIds: number[]): void {
        copyLanguageIds.forEach(id => {
            const languageOption = this.languageOptions.find(option => option.id === id);
            languageOption && (languageOption.value = this.selectedLanguage.value);
        });
        const index = this.languageOptions.indexOf(this.selectedLanguage);

        this.isCopyPanelOpen = false;
        this.copyPanelTriggers.toArray()[index].closeMenu();
    }

}
