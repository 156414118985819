import {MultilingualString} from "@synisys/idm-crosscutting-concepts-frontend";
import {Direction} from "@angular/cdk/bidi";

export class LanguageOption {
    constructor(public id: number,
                public acronym: MultilingualString,
                public direct: Direction,
                public value: string) {
    }
}

export interface FieldTitle {
    customText: object;
    messageId: string;
    titleSrc: string;
    style: string;
}

export interface CopyLanguage {
    id: number,
    name: MultilingualString;
    checked: boolean;
}
