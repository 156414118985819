import {Optional, Pipe, PipeTransform} from '@angular/core';
import {FieldTitle} from "../model/multilingual-interfaces";
import {MultilingualString} from "@synisys/idm-crosscutting-concepts-frontend";
import {Observable} from "rxjs/Observable";
import {ControlsTranslationService} from "../../shared/controls-translation.service";

@Pipe({
    name: 'fieldTitle',
    pure: true
})
export class FieldTitlePipe implements PipeTransform {

    constructor(@Optional() private translationService: ControlsTranslationService) {
    }

    transform(title: FieldTitle, currentLanguageId: number): Observable<string> {
        if (!title.customText && !title.messageId) {
            return Observable.of('');
        }

        return title.customText && !!currentLanguageId ? this.getTitleValue(title.customText, currentLanguageId)
            : this.getMessage(title.messageId);
    }


    private getTitleValue(value: object, langId: number): Observable<string> {
        const map = new Map<number, string>();
        Object.keys(value).forEach(key => {
            map.set(+key, value[key]);
        });
        const langValue = new MultilingualString(map).getValue(langId);
        return Observable.of(langValue);
    }

    private getMessage (key: string): Observable<string> {
        return this.translationService && this.translationService.getMessage(key);
    }
}
