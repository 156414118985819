/**
 * Created by Mariam.Melkonyan on 6/27/2016.
 */
import {ChangeDetectionStrategy, Component, forwardRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {NG_VALUE_ACCESSOR}                        from '@angular/forms';
import {ControlMetadata}                          from '@synisys/idm-dynamic-controls-metadata';
import {MultilingualTextboxSettings}              from '../control-settings/sis-multilingual-textbox.settings';
import {MultilingualComponent}                    from './sis-multilingual.component';
import './sis-multilingual-textbox.component.css';

const MULTILINGUAL_INPUT_CONTROL_VALUE_ACCESSOR = {
    provide    : NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultilingualInput),
    multi      : true,
};

@Component({
               moduleId     : module.id,
               selector     : 'sis-multilingual-textbox',
               templateUrl  : 'sis-multilingual-textbox.component.html',
               encapsulation: ViewEncapsulation.None,
               changeDetection: ChangeDetectionStrategy.OnPush,
               providers    : [MULTILINGUAL_INPUT_CONTROL_VALUE_ACCESSOR],
           })
@ControlMetadata({
                     groups  : [
                         {
                             identity: 'Controls',
                             name    : 'Controls',
                         },
                     ],
                     template: `%{#isreadonly}
                   <ng-container *ngIf="!%{isreadonly}">
                        <sis-multilingual-textbox [maxLength]="%{maxLength}" [placeholder]="%{placeholder}" [id]="'%{id}'" [title]="title"
                                            [languages]="languages" [currentLanguageId]="currentLanguageId" [(ngModel)]="%{field}" [hint]="%{hint}" 
                                            [characterCountSettings]="%{characterCountSettings}" [isRequired]="isRequired" [validations]="validations"></sis-multilingual-textbox>
                   </ng-container>
                   <ng-container *ngIf="%{isreadonly}">
                        <label *ngIf="%{field}?.getValue(currentLanguageId)" [id]="'%{id}'">{{%{field}?.getValue(currentLanguageId)}}</label>
                        <sis-custom-label *ngIf="!%{field}?.getValue(currentLanguageId)" [key]="'reporting.controls.chart-designer.placeholder.noData'"></sis-custom-label>
                   </ng-container>
               %{/isreadonly}
               %{^isreadonly}
                   <sis-multilingual-textbox [maxLength]="%{maxLength}" [placeholder]="%{placeholder}" [id]="'%{id}'" [title]="title"
                                             [languages]="languages" [currentLanguageId]="currentLanguageId" [(ngModel)]="%{field}" [hint]="%{hint}" 
                                             [characterCountSettings]="%{characterCountSettings}" [isRequired]="isRequired" [validations]="validations"></sis-multilingual-textbox>
               %{/isreadonly}`,
                     iconInfo: 'fa-text-width',
                     name    : 'Multilingual textbox',
                     settings: {
                         main: MultilingualTextboxSettings,
                     },
                    isFieldBound: true
                 })
export class MultilingualInput extends MultilingualComponent {

    closeMenu() {
        this.menuTrigger.closeMenu();
    }
}
