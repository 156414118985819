/**
 * Created by Anushavan.Hovsepyan on 11/23/2016.
 */
import {Component, ElementRef} from "@angular/core";
import {BaseMenuComponent} from "./sis-base-menu.component";

import "./sis-menu.component.css";  @Component({
    moduleId: module.id,
    selector: "sis-menu",
    templateUrl: "sis-menu.component.html",
})

/**
 * MenuComponent is kendo menu from children items.
 */
export class MenuComponent extends BaseMenuComponent{

    constructor(private elementRef: ElementRef) {
        super();
    }
}
