/**
 * @Author Perch.Voskerchyan
 * @Date 13.03.2018
 */

import {Component, Input} from "@angular/core";

/**
 * This component provides the functionality and styles of loading bar.
 * The component should be used when the activated router is changed or app loads some data.
 */

import "./sis-progress-spinner.component.css";

@Component({
    moduleId: module.id,
    selector: "sis-progress-spinner",
    templateUrl: "sis-progress-spinner.component.html",
})

export class ProgressSpinnerComponent {
    @Input()
    public id: string;
    /**
     * Theme color palette for the Spinner.
     * available values: primary, accent, warn
     * @type {string}
     * @default primary
     */
    @Input()
    public color: string = "primary";

    /**
     * Mode of the progress circle.
     * Can be determinate or indeterminate
     * @type {boolean}
     * @default false
     */
    @Input()
    public isAnimated: boolean = false;

    /**
     * Value of the progress circle.
     * If the mode of the progress circle is determinate, the {percent} should has a value from 1 to 100
     * @type {number}
     * @default 80
     */
    @Input()
    public percent: number = 80;

    /**
     * Stroke width of the progress spinner.
     * Recommended value is {diameter} * 10
     * @type {number}
     * @default 100
     */
    @Input()
    public strokeWidth: number = 40;

    /**
     * The diameter of the progress spinner (will set width and height of svg).
     * Recommended value is {strokeWidth} / 10
     * @type {number}
     * @default 10
     */
    @Input()
    public diameter: number = 4;

    /**
     * Loading text for the progress spinner.
     * Progress spinner can have the loading text.
     * @type {boolean}
     * @default true
     */
    @Input()
    public hasText: boolean = true;

    /**
     * Position of loading text.
     * can be beside or below
     * @type {boolean}
     * @default true
     */
    @Input()
    public isTextBeside: boolean = true;
}
