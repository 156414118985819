/**
 * Created by nina.kirakosyan on 7/14/2020.
 */
import { Language } from "@synisys/idm-crosscutting-concepts-frontend";
import {
    AfterViewInit,
    Component, ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Optional,
    Output, ViewChild,
} from "@angular/core";
import {CopyLanguage} from "../model/multilingual-interfaces";
import {ControlsTranslationService} from "../../shared/controls-translation.service";
import {Observable} from "rxjs/Observable";
import './sis-multilingual-copy.component.css';
import {MatCheckboxChange} from "@angular/material/checkbox";
import {CopyLanguageCacheService} from "../../shared/copy-language-cache.service";

@Component({
    moduleId: module.id,
    selector: "sis-multilingual-copy",
    templateUrl: "sis-multilingual-copy.component.html",
})
export class MultilingualCopy implements OnInit, AfterViewInit {
    /**
     * Unique id for control. Mainly is used for automated testing.
     * @type {string}
     */
    @Input()
    id: string;

    /**
     * The languages of application. The languages are used to render the copy languages for the selectedLanguage.
     * @type {Language[]}
     */
    @Input()
    languages: Language[];

    /**
     * The primary language. This value is provided when the control is created.
     * @type {number}
     */
    @Input()
    primaryLanguageId: number;

    /**
     * The selected language id. Used for filtering available languages for the copy language options.
     * @type {number}
     */
    @Input()
    selectedLanguageId: number;

    /**
     * Emits an array of CopyLanguage objects for handling the copying in parent multilingual compoenent.
     * @type {CopyLanguage[]}
     */
    @Output()
    onCopyAction: EventEmitter<number[]> = new EventEmitter<number[]>();

    /**
     * Displayed columns for the mat table of copy panel.
     * @type {boolean}
     */
    public displayedColumns = ['nameString', 'checkbox'];

    /**
     * Array of CopyLanguage objects for available for the selected languages.
     * @type {CopyLanguage[]}
     */
    private _copyLanguages: CopyLanguage[];

    /**
     * Search text value from the copy panel.
     * @type {string}
     */
    private _searchText: string;

    /**
     * Filtered rows of CopyLanguages that are rendered in the copy panel at any given time based on searchText.
     * @type {CopyLanguage[]}
     */
    private _filteredRows: CopyLanguage[];

    /**
     * Shows whether or not all of the filtered languages are checked/selected.
     * @type {boolean}
     */
    private _areAllSelected: boolean;

    @ViewChild('table')
    protected table: ElementRef;

    public height: number;

    constructor(@Optional() private translationService: ControlsTranslationService,
                            private copyCacheService: CopyLanguageCacheService) {
    }

    ngOnInit(): void {
        const copyLangIds = this.copyCacheService.getCachedLanguageIds(this.primaryLanguageId);

        this._copyLanguages = [];

        this.languages.forEach(lang => {
            if (lang.getId() !== this.selectedLanguageId) {
                this._copyLanguages.push({
                    id: lang.getId(),
                    name: lang.getAcronym(),
                    checked: copyLangIds.indexOf(lang.getId()) > -1,
                } as CopyLanguage);
            }
        });

        this._filteredRows = this._copyLanguages;
        this.resetAllSelected();
    }

    ngAfterViewInit(): void {
        this.height = this.table.nativeElement.offsetHeight;
    }

    public checkboxAction(event: MatCheckboxChange, copyLanguage: CopyLanguage): void {
        copyLanguage.checked = event.checked;
        this.resetAllSelected()
    }

    public clearSearch(): void {
        this._searchText = '';
        this.resetFilteredRows();
        this.resetAllSelected();
    }

    public onCopy(): void {
        const selectedLangIds = this._copyLanguages.filter(lang => lang.checked).map(lang => lang.id);
        this.copyCacheService.cacheLanguageIds(this.primaryLanguageId,selectedLangIds);

        this.onCopyAction.emit(selectedLangIds);
    }

    get searchText(): string {
        return this._searchText;
    }

    set searchText(value: string) {
        this._searchText = value;
        this.resetFilteredRows();
        this.resetAllSelected();
    }

    get filteredRows(): CopyLanguage[] {
        return this._filteredRows;
    }

    get selectedLanguages(): CopyLanguage[] {
        return this._copyLanguages.filter(copy => copy.checked);
    }

    get areAllSelected(): boolean {
        return this._areAllSelected;
    }

    set areAllSelected(value: boolean) {
        this.filteredRows.forEach(copy => copy.checked = value);
    }

    private resetFilteredRows(): void {
        if (!this._copyLanguages) {
            this._filteredRows = [];
        }
        if (!this._searchText) {
            this._filteredRows = this._copyLanguages;
        }

        this._filteredRows = this._copyLanguages.filter(item => {
            return item.name.getValue(this.primaryLanguageId).toLocaleLowerCase().includes(this._searchText.toLowerCase());
        });
    }

    private resetAllSelected(): void {
        this._areAllSelected = this._filteredRows.length > 0 && !this._filteredRows.some(copy => !copy.checked);
    }

    public getMessage (key: string, placeholder?: any[]): Observable<string> {
        return this.translationService && (!placeholder ? this.translationService.getMessage(key) : this.translationService.getMessageWithPlaceholder(key, placeholder));
    }

    public trackByLanguage(index: number, language: CopyLanguage): number {
        return language.id;
    }
}
