/**
 * @deprecated use 2 separate date pickers or number inputs instead
 * Created by Mariam.Melkonyan on 8/31/2016.
 */
import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output} from "@angular/core";

/**
 * @deprecated use Material Dialog instead.
 * This component provides the functionality of popup window. All the components that should be opened in popup windows,
 * should extend this component and use its functionality.
 */
import './sis-popup-window.component.css';
@Component({
    moduleId: module.id,
    selector: "sis-popup-window",
    templateUrl: "sis-popup-window.component.html",
})

export class PopupWindow implements AfterViewInit, OnDestroy {

    /**
     * Unique id for UI testing
     */
    @Input() id: string;

    /**
     * Determines event emitter that raises events before closing the popup window.
     * @type {EventEmitter<any>}
     */
    @Output() onClose = new EventEmitter<any>();

    private _popupWindow : any;

    /**
     * Determines if the window should be modal
     * @type {boolean}
     * @default
     * @protected
     */
    protected _isModal : boolean = true;

    /**
     * Determines if the user can drag the window
     * @type {boolean}
     * @default
     * @protected
     */
    protected _isDraggable : boolean = true;

    /**
     * Determines if the use can resize the window
     * @type {boolean}
     * @default
     * @protected
     */
    protected _isResizeable : boolean = true;

    /**
     * Determines the width of the window
     * @type {any}
     * @default
     * @private
     */
    private _width : any = 1000;

    private initialized: boolean = false;

    @Input()
    public set width(width: any) {
        this._width = width;
        if(this._popupWindow) {
            this._popupWindow.width(this._width);
        }
    }

    public get width(): any {
        return this._width;
    }

    /**
     * Determines the height of the window
     * @type {any}
     * @default
     * @private
     */
    private _height : any = 1000;

    @Input()
    public set height(height: any) {
        this._height = height;
        if(this._popupWindow) {
            this._popupWindow.height(this._height);
        }
    }

    public get height(): any {
        return this._height;
    }

    /**
     * Determines the title of the window
     * @type {string}
     * @private
     */
    private _title : string = "";

    @Input()
    public set title(title: string) {
        this._title = title;
        if(this._popupWindow) {
            this._popupWindow.title(this._title);
        }
    }

    public get title(): string {
        return this._title;
    }

    private _elementRef: ElementRef;


    constructor(elementRef : ElementRef) {
        this._elementRef = elementRef;
    }

    /**
     * The popup window is created after the view is initialized.
     */
    public ngAfterViewInit() {
        if (!this.initialized) {
            this._createPopupWindow();
            this.initialized = true;
        }
    }

    /**
     * Destroys popup window component.
     */
    public ngOnDestroy(): void {
        this._popupWindow.destroy();
    }

    /**
     * Created the Kendo UI window according to the given parameters (width, height, title, etc.)
     * All the parameters can be overwritten by the components which derive from this component
     * to use popup window functionality
     * @protected
     */
    protected _createPopupWindow() : void {
        let t = this;
        (<any>$(this._elementRef.nativeElement)).kendoWindow({
            modal: t.isModal(),
            animation: false,
            draggable: t.isDraggable(),
            resizable: t.isResizeable(),
            title: t.title,
            width: t.width,
            height: t.height,
            close: function(e: any) {
                t.onClose.emit(e);
            }
        });
        $(this._elementRef.nativeElement).data("kendoWindow").wrapper.attr("id", `${this.id}-popup-container`);
        this._popupWindow = $(this._elementRef.nativeElement).data("kendoWindow").center();
    }
    /**
     * Opens the popup window.
     */
    protected open() : void {
        this._popupWindow.center().open();
    }

    /**
     * Closes the popup window.
     */
    protected close() : void {
        this._popupWindow.close();
    }

    public isModal() : boolean {
        return this._isModal;
    }

    public isDraggable() : boolean {
        return this._isDraggable;
    }

    public isResizeable() : boolean {
        return this._isResizeable;
    }
}
