import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import './sis-notification-box.component.css';

@Component({
    moduleId: module.id,
    selector: 'sis-notification-box',
    templateUrl: "sis-notification-box.component.html",
})
export class NotificationBoxComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public id: string;
    @Input() public mode: string;
    @Input() public modeText: string = null;
    @Input() public message: string;
    @Input() public visible: boolean;
    @Input() public color: string;
    @Input() public icon: string;
    @Input() public hide: boolean;
    @Output() public onHide: any = new EventEmitter();

    public modes: any [];

    public fadeOut: any;
    public fadeOutCall: any;

    ngOnInit(): void {
        this.modes = ['Default', 'Success', 'Warning', 'Error', 'Custom']
    }

    hideNotification() {
        this.clearTimeouts();
        this.onHide.emit();
    }

    ngOnChanges(changes: any): void {
        if(changes && changes.visible && changes.visible.currentValue) {
            this.fadeOutCall = setTimeout(() => {
                this.fadeOut = true;
            }, 3000);

            this.fadeOut = setTimeout(() => {
                this.visible = false;
            }, 3400)
        }

    }

    ngOnDestroy(): void {
        this.clearTimeouts();
    }

    clearTimeouts() {
        this.visible = false;
        if (this.fadeOut)
            clearTimeout(this.fadeOut);
        if (this.fadeOutCall)
            clearTimeout(this.fadeOutCall);
    }
}

