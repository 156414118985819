import {Pipe, PipeTransform} from '@angular/core';
import {MultilingualString} from "@synisys/idm-crosscutting-concepts-frontend";

@Pipe({
    name: 'multilingual',
    pure: true
})
export class MultilingualStringPipe implements PipeTransform {

    transform(value: MultilingualString, currentLanguageId: number): string {
        if (!value || !currentLanguageId || !value.getValue(currentLanguageId)) {
            return '';
        }

        return value.getValue(currentLanguageId);
    }
}
