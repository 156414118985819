/**
 * Created by tamara.aprikyan on 7/12/2017.
 */
import {Component, ElementRef} from "@angular/core";
import {BaseMenuComponent} from "./sis-base-menu.component";

/**
 * @deprecated use {@link MenuComponent} instead.
 * TODO: Remove after month
 */
import "./sis-dropdown-button.component.css";  @Component({
    moduleId: module.id,
    selector: "sis-drop-down-button",
    templateUrl: "sis-dropdown-button.component.html",
})

/**
 * DropDownButtonComponent is kendo menu from children items with drop down button design.
 */
export class DropDownButtonComponent extends BaseMenuComponent{

    constructor(private elementRef: ElementRef) {
        super();
    }
}
